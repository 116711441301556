<template>
    <div class="back-dialog">
        <el-dialog v-dialog-drag center :visible.sync="dialogShow.show" :width="defaultWidth" top="25vh">
            <slot name="content"></slot>
            <div slot="title">
                <el-image class="img1" :src="img1Url" fit="fill"></el-image>
                <div class="head">
                    <slot name="title"></slot>
                    <div class="close" @click="dialogShow.show = false">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <slot name="footer"></slot>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialogShow: {
            type: Object,
            default: () =>{
                return {
                    show: false
                }
            }
        },
        defaultWidth: {
            type: String,
            default: '453px'
        }
    },
    data() {
        return {
            img1Url: require('@/assets/img/home-dialog.png'),
        }
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .back-dialog{
        .el-dialog{
            border-radius: 10px;
            &__header{
                padding: unset;
                position: relative;
                .head{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-right: 20px;
                    .title{
                        line-height: 54px;
                        font-size: 16px;
                        font-weight: bold;
                        padding-left: 20px;
                        i{
                            padding-right: 10px;
                        }
                    }
                    .close{
                        padding: 5px;
                        .el-icon-close{
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                    
                }
            }
            &__headerbtn{
                display: none;
            }
            &__body{
                padding: 30px 67px;
            }
            &__footer{
                .dialog-footer{
                    .el-button{
                        padding: 16px 54px;
                        background-color: $main;
                        border: 0;
                    }
                }
            }
        }   
    }
</style>